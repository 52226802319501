import React from 'react'
import Layout from "../../components/mdxLayout";

/**
 * Termporary placeholder for the SMS pricing page to avoid flash of 404 content
 */
export default function SmsPricing() {
  return (
    <Layout>
      <>
      </>
    </Layout>
  )
}
